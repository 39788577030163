@import nib

global-reset();

.language-switch {
  text-align: center;
  li {
    display: inline-block;
    padding: 0 3px;
  }
  a {
    text-decoration: none;
    color: #222222;
    text-transform: lowercase;
    font-size: 11px;
    &:hover {
      color: #2222bb;
    }
    &.current {
      color: #aa2222;
      text-decoration: underline;
      cursor: default;
      pointer-events: none;
    }
  }
}

.flag {
  width: 16px;
  height: 11px;
  background:url(../../img/flags.png) no-repeat;
  display:inline-block;
  &.flag-at {background-position: -16px 0}
  &.flag-de {background-position: -32px 0}
  &.flag-es {background-position: -48px 0}
  &.flag-fr {background-position: 0 -11px}
  &.flag-gb {background-position: -16px -11px}
  &.flag-it {background-position: -32px -11px}
  &.flag-no {background-position: -48px -11px}
  &.flag-pl {background-position: 0 -22px}
  &.flag-pt {background-position: -16px -22px}
  &.flag-ru {background-position: -32px -22px}
  &.flag-us {background-position: -48px -22px}
  &.flag-en {background-position: -48px -22px}
}


body {
  background-attachment: scroll;
  background-color: #000;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  min-height: 1200px;
  width: 100%;
  height: 100%;
}

.sprite {
  background-image: url(../../img/sprite.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: transparent !important;
}

#bg, #bg-buffer {
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: absolute;
}

#grad-left {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent url(../../img/grad_lt.png) repeat-y 0 0;
}

#grad-right {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent url(../../img/grad_rt.png) repeat-y 100% 0;
}

#menu-right {
  border-radius: 5px;
  position: absolute;
  top: 260px;
  right: 20px;
  display: block;
  width: 230px;
  overflow: hidden;
  background: transparent url(../../img/firana_white.png);

  #festival-area-title {
    display: block;
    width: 156px;
    height: 22px;
    background-position: -514px -5px;
    margin: 18px 0 0 40px;
    b {
      display: none;
    }
  }
  #festival-area-menu {
    margin: 16px 0 11px 0;
    list-style-type: none;
    > li > a {
      display: block;
      width: 153px;
      height: 45px;
      margin: 0 0 2px 16px;
      color: #fff;
      text-decoration: none;
      line-height: 45px;
      padding: 0 0 0 45px;
      background-position: -692px -2px;
      text-transform: uppercase;
      &:hover, &.current {
        background-position: -692px -51px;
      }
    }
  }
}

#header {
  background-attachment: fixed;
  background-color: transparent;
  background-image: url(../../img/firana_black.png);
  background-repeat: repeat;
  height: 84px;
  width: 100%;
  position: absolute;
  z-index: 50;

  table {
    width: 100%;
    height: 84px;
    border: 0;
  }

  #flower-lt {
    background-color: transparent;
    background-image: url(../../img/flower_top.png);
    background-repeat: repeat-x;
    background-position: right 50%;
  }

  #flower-rt {
    background-color: transparent;
    background-image: url(../../img/flower_top.png);
    background-repeat: repeat-x;
    background-position: left 50%;
  }

  #menu-top {
    width: 620px;
    text-align: center;
    a {
      display: block;
      color: transparent !important;
      height: 35px;
      clear: none;
      line-height: 35px;
      float: left;
      margin: 0 20px;
      b {
        display: none;
      }
      &#menu-top-apply {
        width: 73px;
        background-position: -10px 0;
        &:hover, &.current {
          background-position: -10px -36px;
        }
      }

      &#menu-top-home {
        width: 80px;
        background-position: -94px 0;
        &:hover, &.current {
          background-position: -94px -37px;
        }
      }

      &#menu-top-information {
        width: 183px;
        background-position: -175px 0;
        &:hover, &.current {
          background-position: -175px -37px;
        }
      }
    }
  }

  #menu-top-centerer {
    margin: 0 auto !important;
    width: 457px;
    text-align: center;
  }

}

#logo {
  background-image: url(../../img/logo.png);
  background-repeat: no-repeat;
  background-position: 0 -232px;
  width: 466px;
  height: 235px;
  position: absolute;
  left: 50%;
  top: 120px;
  margin-left: -232px !important;
  z-index: 1000;
}

.province-herb {
  position: absolute;
  top: 95px;
  right: 220px;
  z-index: 60;
}

#stamp-of-mayor {
  position: absolute;
  top: 65px;
  right: 35px;
  z-index: 60;
}

#menu-left {
  position: absolute;
  z-index: 60;
  top: 160px;
  left: 20px;
  width: 220px;
  padding-left: 20px;
  background: transparent url(../../img/firana_white.png);
  border-radius: 5px;

  .language-switch {
    margin-top: 20px;
  }

  #information-title {
    display: block;
    width: 147px;
    height: 27px;
    background-position: -365px -5px;
    margin: 18px 0 0 30px;
    b {
      display: none;
    }
  }

  #menu-left-content {
    width: 200px;
    margin: 0 0 0 10px;
    ul {
      margin: 20px 0;
      &.main-articles {
        margin-left: -10px;
      }
      > li > a {
        color: #000;
        text-decoration: underline;
        margin: 8px 0;
        &:hover, &.current {
          color: #f00;
        }
      }
    }
  }
  .main-left-content-other {
    margin: 0 0 0 -10px;
    padding: 0;
    font-weight: 600;
    font-size: 18px;
    ul {
      font-weight: 100;
      font-size: 16px;
      margin: 3px 0 10px 5px !important;
      padding: 3px 0 10px 5px !important;
    }
  }
}

.content-frame {
  position: absolute;
  z-index: 70;
  width: 700px
  left: 50%;
  top: 390px;
  margin-left: -350px;
  background: transparent url(../../img/firana_white.png);
  padding: 20px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 3px 3px 11px #000000;
  text-align: left;
  font-size: 16px;
  .center {
    text-align: center;
  }
  a {
    color: #222266;
  }
  p {
    padding: 8px 0;
  }
  h2 {
    font-weight: 700;
    font-size: 28px;
    padding-bottom: 12px;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ul, ol {
    margin-left: 20px;
  }
  strong, b {
    font-weight: 700;
  }
  em {
    text-decoration: underline;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    padding: 15px 0;
  }
  h4 {
    font-weight: 700;
    font-size: 20px;
    padding: 10px 0;
  }
  h5 {
    text-decoration: underline;
    font-weight: 400;
    padding: 5px 0;
    font-size: 18px;
  }
  ul.galleries {
    list-style-type: none;
    margin: 20px 0;
    li {
      display: inline-block;
      padding: 0 10px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.costs-page {
  .language-switch {
    margin-bottom: 10px;
  }
  .options {
    text-align: center;
    overflow: hidden;
    strong {
      font-weight:bold;
    }
    .photos-and-info-button {
      color: #222266;
      margin-left: 100px;
    }
    .option {
      display: inline-block;
      overflow: hidden;
      width: 220px;
      height: 240px;
      border: solid 1px #555;
      text-align: center;
      margin: 5px 2px;
      border-radius: 10px;
      box-shadow:  2px 2px 2px 2px rgba(0,0,0,0.2);
      background: linear-gradient(to bottom,  rgba(30,87,153,1) 0%,rgba(125,185,232,0) 100%); /* W3C */

      .legend {
        text-align: center;
        font-weight: bolder;
        font-size: 20px;
        border-bottom: solid 1px #555;
      }
      .accommodation {
        height: 40px;
        a {
          color: #222;
        }
      }
      .price-pl {
        padding: 6px 0 0 0;
        font-size: 13px;
      }
      .price-us {
        font-size: 13px;
      }
      .price {
        display: block;
        text-align: center;
        color: #974c00;
        font-weight: bolder;
        font-size: 17px;
        .price-description {
          font-size: 12px;
          color: #222;
        }
      }
    }
  }
  .price-includes {
    margin: 20px 60px;
    line-height: 30px;
    list-style-type: disc;
  }
}

ul.gallery {
  list-style-type: none;
  text-align: center;

  li {
    display: inline;
  }

  img {
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px rgba(235, 235, 235, 0.9);
    &:hover {
      box-shadow: 0 0 1px 1px rgba(35, 35, 125, 0.5);
    }
  }

  a {
    text-decoration: none;
  }
}

#footer {
  position:absolute;
  z-index: 60;
  bottom: 0;
  float: left;
  display: block;
  left: 0;
  width: 100%;
  height: 150px;
  background: transparent url(../../img/footer_bg.png) repeat-x 0 0;
  color: #eee;
  h2 {
    color: #f00;
    font-size: 32px;
  }
  a {
    color: #cc0;
  }
  #copyrights {
    font-size: 12px;
    color: #ccc;
    float: right;

    margin-right: 20px;
  }
  #footer_holder {
    width: 700px;
    margin: 0 auto;
    .footer_col {
      display: block;
      float: left;
      width: 700px;
      margin: 20px 0 0 20px;
      h3 {
        color: #AF0000;
        font-size: 18px;
        font-weight: normal;
      }
      p {
        color: #fff;
      }
    }
  }

}
